.brandImage {
  height: 35px;
  width: 35px;
}

.alertDiv {
  min-height: 66px;
}

.contentDiv {
  min-height: 1080px;
}

.contentDivAds {
  min-height: 870px;
}

.contentDivAdsDev {
  min-height: 870px;
}

.footerPin {
  position: relative;
  left: 0;
  bottom: 0;
}

.ad {
  border: solid blue;
  min-height: 88px;
}

.imageCanvas {
  margin: auto;
  max-width: 970px;
}

div.container {
  max-width: 1010px;
}

.galleryImageThumb {
  max-width: 200;
}

.editIcon {
  position: absolute;
  bottom: 0px;
  right: 25px;
}
.deleteIcon {
  position: absolute;
  bottom: 0px;
  right: 2px;
}

.galleryText {
  position: absolute;
  top: 0px;
  left: 210px;
}

.booksGalleryText {
  position: absolute;
  top: 0px;
  left: 15px;
}

.statsTable td {
  width: 33%;
}

@media screen and (min-width: 600px) {
  .authForms {
    max-width: 40%;
    /* background-color: pink; */
  }
  body {
    padding-top: 66px;
  }

  .googleAdSize {
    /* border: solid hotpink; */
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  body {
    padding-top: 220px;
  }
}
